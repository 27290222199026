import React, { useEffect, useState } from 'react';
import VideoPlayer from './layouts/VideoPlayer';
import { useAssetMutation } from 'src/redux/api/usersAPI';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store/store';
import decodeJwtToken from 'src/utils/decode-jwt-token';
import { pubnub_signal_config } from 'src/config/config';
import Loader from '../loader/loader';
import { PUBNUB_SIGNALS } from './constants';
import { getLoggedInUserInfo } from 'src/redux/service/auth';
import { useNavigate } from 'react-router-dom';
import analytics from 'src/config/firebase-config'; 
import { logEvent } from 'firebase/analytics';

const Player = () => {
  // Variables
  const path = window.location.pathname;
  const [asset] = useAssetMutation();
  const { assetDetails } = useSelector((state: RootState) => state.auth);

  // UseStates
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assetData, setAssetData] = useState<any>();
  const [signal, setSignal] = useState<string>('');
  const navigate = useNavigate();

  // Functions
  const getAssetDetails = async (params: string) => {
    try {
      const response: any = await asset({ assetId: '2' });
      const decodedToken = decodeJwtToken(response?.data);
      const { data: DecodeData } = decodedToken;
      return getCurrentAsset(DecodeData, params);
    } catch (err) {
      console.error('Error while fetching api:', err);
      return null;
    }
  };

  const getCurrentAsset = (data: any, params: string) => {
    return data?.find((asset: any) => asset.path == params);
  };

  const checkAndFetchAsset = async (assetDetails: any, params: string) => {
    // Try to get the asset from the local store first
    let asset = getCurrentAsset(assetDetails, params);

    // If the asset is not found in the local store, fetch from the API
    if (!asset) {
      asset = await getAssetDetails(params); // await here
    }

    return asset;
  };

  const handleSignals = (event: any) => {
    setSignal(event?.message?.content);
  };

  // UseEffects
  useEffect(() => {
    const isUser = getLoggedInUserInfo();
    if (!isUser) {
      navigate('/sign-in');
      return;
    }
    const params = '2';
    if (params) {
      const asset = checkAndFetchAsset(assetDetails, params);
      if (asset) {
        asset.then((res: any) => {
          setSignal(res.last_signal);
          setAssetData(res);
          setIsLoading(false);
        });
      }
    }
  }, [path]);

  useEffect(() => {
    const listenerParams = { signal: handleSignals };
    if (assetData?.externalId) {
      pubnub_signal_config.addListener(listenerParams);
      pubnub_signal_config.subscribe({ channels: [assetData?.externalId] });
    }

    return () => {
      pubnub_signal_config.unsubscribe({ channels: [assetData?.externalId] });
      pubnub_signal_config.removeListener(listenerParams);
    };
  }, [pubnub_signal_config, assetData]);

  useEffect(() => {
    logEvent(analytics, 'FireBase video page initialized');
  }, []);

  return !assetData ? (
    <div>
      <Loader />
    </div>
  ) : (
    <VideoPlayer signal={signal} assetData={assetData} />
  );
};

export default Player;
