import { useEffect, useState } from "react";

const HomeBanner = ({ sliderList, onCheckoutLoader }: any) => {
  useEffect(() => {
    console.log(sliderList);
  }, [sliderList]);

  return (
    <div className="home-banner">
      <div className="banner-content">
        <div style={{ textAlign: "center" }}></div>
        {sliderList.length > 0 && (
          <div
            dangerouslySetInnerHTML={{ __html: sliderList[1].description }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeBanner;
  