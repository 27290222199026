// auth.js
import decodeJwtToken from "src/utils/decode-jwt-token";

export const setAuthToken = (token: string) => {
    localStorage.setItem('token', token);
};


export const setUsertokenId = (token: string) => {
    const { data } = decodeJwtToken(token);
    localStorage.setItem('userID', data.userId);
};

export const setUserinfo = (userinfo: string) => {
    localStorage.setItem('userInfo', userinfo);
};

export const getUserinfo = () => {
    return localStorage.getItem('userInfo');
};

export const getAuthToken = () => {
    return localStorage.getItem('token');
};

export const getuseridToken = () => {
    return localStorage.getItem('userID');
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userID');
    setGuestUser(true);
    window.location.href = '/home';
};

export const isAuthenticated = () => {
    const token = getAuthToken();
    // Check if token exists and is not expired or invalid
    return !!token; // Modify this based on your authentication logic
};


export const setGuestUser = (user: boolean) => {
    if (user) {
        localStorage.setItem('isGuest', 'true');
    } else {
        localStorage.setItem('isGuest', 'false');
    }
};

export const getGuestUser = () => {
    return localStorage.getItem('isGuest');
};

export const getLoggedInUserInfo = () => {
    const isUser = getGuestUser();
    if (isUser === 'false') {
        const token = getAuthToken();
        const { data } = decodeJwtToken(token);
        return data;
    };
}


