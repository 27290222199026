import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {getLoggedInUserInfo} from "src/redux/service/auth";
import "./auth.scss";
import BackGroundPageWrapper from "src/components/back-ground-page-wrapper/back-ground-page-wrapper";
import Loader from "src/components/loader/loader";
import { useLocation } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(60);
  const [password, setPassword] = useState('');  // Use password state
  const [isOtpEnable, setOptEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null || "");
  const [isUserForm, setIsUserForm] = useState(false);
  const location = useLocation();
  const isUser = getLoggedInUserInfo();
  const correctPassword = 'DMBGORGE2024'; // Replace with your actual password

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSignIn = (e: any) => {
    e.preventDefault();

    if (password === correctPassword) {
      localStorage.setItem('isAuthorized', 'true');
      navigate('/', { replace: true });  // Ensure the navigation occurs
      window.location.reload();  // Force the page to refresh
    } else {
      alert('Incorrect password, please try again.');
    }
  };

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (isUser && location.pathname === '/sign-in') {
      navigate('/home'); // if user is logged in & tries to use back button to go back, it will redirect user to home page
    }
  }, [isUser, location, navigate]);

  return (
    <BackGroundPageWrapper>
      {!loading ? (
        <div className="main-register">
          {!isOtpEnable && !isUserForm && (
            <div className="create-account-panel">
              <div className="create-account-panel-heading">
                Connect with us to get your tickets.
              </div>
              <div className="create-account-panel-description">
                Tickets need to be registered to a single user to ensure the
                quality of your experience.
              </div>
            </div>
          )}

          <div className="Middel-box">
            <form onSubmit={handleSignIn}>
              <div className="fill-box">
                {!isOtpEnable && !isUserForm && (
                  <>
                    <div className="fill-box-heading">Please enter the Password</div>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}  // Bind the password state
                      onChange={handlePasswordChange} // Handle password change
                    />
                    <br></br>
                    <button type="submit" className="google-button-wrapper">
                      Continue
                    </button>
                  </>
                )}
              </div>

              {error && !isOtpEnable ? (
                <div style={{ margin: "10px 0px 0px 0px" }}>{error}</div>
              ) : (
                <div style={{ margin: "10px 0px 0px 0px", height: '21px' }}>{' '}</div>
              )}
            </form>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </BackGroundPageWrapper>
  );
};

export default Register;
