import React, { useEffect, useState } from "react";
import Layout from "src/layout/layout-container";
import SignIn from "../src/screen/auth/auth"; // Adjust the path as necessary
import { useDispatch } from "react-redux";
import { useGuestUserMutation } from "src/redux/api/authAPI";
import { useUserNavigationMutation } from "src/redux/api/usersAPI";
import { getAuthToken, setAuthToken, setGuestUser } from "src/redux/service/auth";
import decodeJwtToken from "src/utils/decode-jwt-token";
import { setUserNavigation } from "./redux/reducer/authSlice";
import "./style.scss";
import "./App.scss";

const App = () => {
  const [guestUser] = useGuestUserMutation();
  const [userNavigation] = useUserNavigationMutation();
  const dispatch = useDispatch();
  const isBootstrapApp = getAuthToken();
  const isAuthorized = localStorage.getItem('isAuthorized') === 'true';

  useEffect(() => {
    async function getGuestToken() {
      const guestToken: any = await guestUser({});
      const { data } = guestToken;
      setAuthToken(data);
      setGuestUser(true);
    }
    if (!getAuthToken()) getGuestToken();
  }, []);

  useEffect(() => {
    if (isBootstrapApp && isAuthorized) getNavigation();
  }, [isBootstrapApp, isAuthorized]);

  const getNavigation = async () => {
    const navList: any = await userNavigation({});
    const decodeToken = decodeJwtToken(navList?.data);
    if (decodeToken.success) {
      dispatch(setUserNavigation(decodeToken));
    }
  };

  const navigate = (path: any) => {
    window.history.pushState({}, '', path);
    window.dispatchEvent(new Event('popstate'));
  };

  useEffect(() => {
    const handlePopState = () => {
      // Force update the component on URL change
      window.location.reload();
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const renderContent = () => {
    const currentPath = window.location.pathname;

    if (!isAuthorized && currentPath !== '/auth') {
      navigate('/auth');
      return <SignIn />;
    }

    if (currentPath === '/auth') {
      return <SignIn />;
    }

    if (isAuthorized) {
      return <Layout />;
    }

    // Add more paths as needed
    return <div>404 - Not Found</div>;
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default App;
