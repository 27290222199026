import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import LiveChat from 'src/components/live-chat/live-chat';
import React, { useEffect, useState } from 'react';
import {
  PUBNUB_PUBLISH_KEY,
  PUBNUB_SUBSCRIBE_KEY,
  CHAT_PUBNUB_USER_ID,
} from 'src/config/config';
import { ReactComponent as LiveChatIcon } from 'src/assets/icons/Icons-15.svg';
import { PUBNUB_SIGNALS } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from "src/redux/store/store";
import { getuseridToken } from 'src/redux/service/auth';

interface RightBottomProps {
  eventLabel: string | null;
  setShowLiveChat: React.Dispatch<React.SetStateAction<boolean>>;
  showLiveChat: boolean;
  signal: string | null;
}
const RightBottom: React.FC<RightBottomProps> = ({
  eventLabel, signal,
  setShowLiveChat,
  showLiveChat,
}) => {

  const isMobileOrTablet = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };
  
  const userId = getuseridToken();
  // const { userInfo } = useSelector((state: RootState) => state.auth);
  // const userId = userInfo?.userId;
  const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    userId: userId ? userId : '',
  });

  const [showCastPopup, setShowCastPopup] = useState<boolean>(false);
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
  const chatdisplay = window.matchMedia("(orientation: portrait)");
  const toggleChat = () => {
    setShowLiveChat(!showLiveChat);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    setShowLiveChat(mediaQuery.matches);
    const handleOrientationChange = () => {
      setIsPortrait(mediaQuery.matches);
      setShowLiveChat(mediaQuery.matches);
      console.log(chatdisplay.matches)
    };

    // Add event listener to detect orientation changes
    mediaQuery.addEventListener('change', handleOrientationChange);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, [isPortrait]);

    return (
      <>
        <div className="rightMenuBottom">
          {eventLabel && signal !== PUBNUB_SIGNALS.STREAM_END && (isMobileOrTablet() ? isPortrait : true)  &&  (
            <ul>
              <li onClick={toggleChat}>
                <LiveChatIcon
                  className={`player-icon ${showLiveChat ? 'activeElement' : ''}`} 
                  />
                <svg className="closeicon" xmlns="http://www.w3.org/2000/svg" width="23.784" height="23.784" viewBox="0 0 23.784 23.784">
                  <g id="Group_2" data-name="Group 2" transform="translate(-565.086 -286.131)">
                    <g id="Group_1" data-name="Group 1" transform="translate(0 19.045)">
                      <line id="Line_1" data-name="Line 1" x2="20.955" y2="20.955" transform="translate(566.5 268.5)" fill="none" stroke="#969696" stroke-linecap="round" strokeWidth="2"/>
                      <line id="Line_2" data-name="Line 2" x2="20.955" y2="20.955" transform="translate(587.455 268.5) rotate(90)" fill="none" stroke="#969696" stroke-linecap="round" strokeWidth="2"/>
                    </g>
                  </g>
                </svg>

              </li>

              {/* <li>
                        <img
                          alt="reply"
                          src="/images/icons/Icons-16.svg"
                          className="icon"
                        ></img>
                      </li> */}

              {/* <div className="divider"></div> */}
            </ul>
          )}

          {/* <ul>
                <li>
                  {showCastPopup && <div className="castPopup">Cast to a device</div>}
                  <img
                    alt="chrome-cast"
                    src="/images/icons/Icons-17.svg"
                    className="icon"
                    onClick={() => setShowCastPopup(!showCastPopup)}
                  ></img>
                </li> */}
          {/* <li>
                <img
                  alt="video"
                  src="/images/icons/Icons-18.svg"
                  className="icon"
                ></img>
              </li>
              <li>
                <img
                  alt="setting"
                  src="/images/icons/Icons-31.svg"
                  className="icon-small"
                ></img>
              </li> */}
          {/* </ul> */}
        </div>
        <div>
          {eventLabel && (
            <PubNubProvider client={pubnub}>
              <div className={showLiveChat ? 'active-chat' : 'in-active-chat'}>
                <LiveChat eventLabel={eventLabel} />
              </div>
            </PubNubProvider>
          )}
        </div>
      </>
    );
  };

export default RightBottom;
